import { setCountryCode } from '@adobe-fonts/site/lib/I18N.js';

export function initI18n(switchLocale) {
  const localInfoTag = document.getElementById('/locale_info');
  if (localInfoTag) {
    const localInfo = JSON.parse(localInfoTag.innerText);
    if (localInfo.selectedLocale) {
      setCountryCode(localInfo.countryCode);
      switchLocale(localInfo.selectedLocale.replace('_', '-'));
    }
  }
}
